<template>
	<v-container fluid>
		<v-app-bar fixed color="background" elevation="0" class="py-lg-2 pr-lg-3 pr-1">
			<v-btn icon rounded exact :to="{name: 'IntroWelcome'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn color="accent" rounded :to="{name: 'IntroProfile'}">next</v-btn>
		</v-app-bar>
		<v-container>
			<v-row>
				<v-col cols="12" class="text-center pt-5">
					<h4 class="text-h4 text-center mb-15">free trial</h4>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" sm="8" md="10" lg="6">
					<v-card color="primary" dark>
						<v-card-title class="text-h5">you can try rewind for FREE for 60 days</v-card-title>
						<v-card-text class="text">once you trial period is over you can choose to subscribe on a monthly or yearly subscription.</v-card-text>
						<v-card-text>
							<v-row class="justify-center">
								<v-col cols="12" md="6"  class="px-0 px-md-3">
									<v-card color="transparent">
										<v-card class="price">
											<v-card-title class="text-h6 accent">{{ 2.99 | format_currency('GBP') }} - month</v-card-title>
										</v-card>
									</v-card>
								</v-col>
								<v-col cols="12" md="6"  class="px-0 px-md-3">
									<v-card color="transparent">
										<v-card class="price">
											<v-card-title class="text-h6 accent">{{ 29.99 | format_currency(('GBP')) }} - year</v-card-title>
										</v-card>
									</v-card>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
export default {
	name: "Trial",
	metaInfo: {
		title: 'Trial',
	},
	computed: {
		pricesOrdered() {
			return this.prices.sort((a, b) => a.unit_amount - b.unit_amount)
		},
	},
	data: () => ({
		prices: []
	}),
	mounted() {
		this.getPrices()
	},
	methods: {
		getPrices() {
			this.$api.stripe.prices()
				.then(response => this.prices = response.data)
				.catch(error => console.log(error))
		},
	}
}
</script>

<style scoped>

</style>